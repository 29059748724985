* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
table,
th,
td {
	border: 1px solid #e1e1e1;
	border-collapse: collapse;
	padding: 10px;
	text-align: left;
}
