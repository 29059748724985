input[type="text"] {
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	border-bottom: 1px dotted #2196f3 !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
}

.heading {
	color: #2196f3;
}

.control {
	padding-top: 7px;
}

.reciept {
	border-top: 5px solid #2196f3;
	-webkit-box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
	-moz-box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
	box-shadow: 0px 5px 21px -2px rgba(0, 0, 0, 0.47);
	margin-top: 10px;
	margin-bottom: 10px;
}
